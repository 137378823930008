// Styles for Nodes
// -----------------------------------------------------------------------------
.img-fluid {
  width: 100%;
}
.node--section--tab {
  font-size: $h2-font-size;
}

.bliss--teaser--small {
  a {
    color: inherit;
  }
}

.pane-node-content,
.pane-community-partners-profile {
  .field-name-author {
    a {
      color: $wellhub-primary;
    }
  }
  a {
    color: $wellhub-body-links;
    overflow-wrap: anywhere;
  }
  a.badge-wellhubgrey {
    color: white;
  }
}

.event-capacity-full {
  background: $danger;
  color: white;
  text-align: center;
  padding: 1rem;
  border:2px solid darken($danger,10);
}

.media-element-container {
  flex-wrap: wrap;
  .media-element {
    @extend .img-fluid;
  }
  .image--meta {
    width: 100%;
    clear: both;
    background: $wellhub-primary;
    color: $wellhub-primary-text;
    padding: 0.25rem;
    font-size: 1rem;
    .wysiwyg-title {
      float: left;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    .wysiwyg-attr {
      float: right;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}

.node-unpublished {
  .bg-white {
    //background-color: red !important;
    //@extend .bg-info;
  }
  .status--unpublished {
    float: right;
    padding: 1rem;
    border-radius: 0.4rem;
    @extend .bg-info;
    color: $white;
  }
}
